import styled from 'styled-components'

export const Flex = styled.div`
	flex-direction: column;
	text-align: center;
	align-items: center;
	color: #f9f5f1;
	padding-bottom: 5vh;
	background-color: #212121;
	z-index:2;
	position: relative;
	font-family: 'IBM Plex Mono', serif;
`