module.exports = {
	defaultTitle: "Jacob Miller's Portfolio",
	logo: 'https://jacobpaulmiller/favicon/favicon.png',
	author: 'Jake Miller',
	url: 'http://jacobpaulmiller.com',
	legalName: 'Jacob Miller',
	defaultDescription: 'Jacob Miller. Designer. Developer. Doer.',
	socialLinks: {
		twitter: 'http://www.twitter.com/HaiJake',
		github: 'https://github.com/JakePMiller',
		linkedin: 'https://www.linkedin.com/in/jacobpaulmiller/',
		instagram: 'https://instagram.com/HaiJake'
	},
	themeColor: '#212121',
	backgroundColor: '#212121',
	social: {
		facebook: 'JPM1996',
		twitter: '@haijake',
	},
	address: {
		city: 'Fishers',
		region: 'IN',
		country: 'USA',
		zipCode: '46037',
	},
	contact: {
		email: 'jacobmiller7119@live.com',
		phone: '812-629-6891',
	},
	foundingDate: '2019'}
