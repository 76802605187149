import React from 'react'
import { Flex } from './styles'

export const Footer = () => (
		<Flex>
				<span>
					 Built & Designed by Jacob Miller | {new Date().getFullYear()}
				</span>
		</Flex>
)
