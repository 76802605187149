import { css } from 'styled-components';

const sizes = {
  desktop: 1440,
  laptop: 1280,
  tablet: 1024,
  mobileLandscape: 823,
  mobile: 480,
  smallMobile: 320,
};

export const media = Object.keys(sizes).reduce((accumulator, label) => {
const emSize = sizes[label] / 16;
  accumulator[label] = (...args) => css`
    @media (max-width: ${emSize}em) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {});

export default media;