import styled from 'styled-components'
import { media } from '../../Common/Global/media.js'

export const Wrapper = styled.div`
	height:auto;
	position: relative;
	padding-left: 12.5vw;
	padding-right: 12.5vw;
	@media (max-width: 750px) {
		padding-left: 5vw;
		padding-right: 5vw;	
	}
}
`
